.placeholder-fields-scrollable {
  overflow-y: scroll;
  padding-right: 10px;
  max-height: 220px;
}

.placeholder-fields-scrollable::-webkit-scrollbar {
  width: 10px;
}

.placeholder-fields-scrollable::-webkit-scrollbar-thumb {
  background-color: map-get($theme-colors, 'primary');
  border-radius: 4px;
}

.placeholder-fields-scrollable::-webkit-scrollbar-thumb:hover {
  background-color: lighten(map-get($theme-colors, 'primary'), 10%);
}
