.accordion {
  background-color: $white;
}
.accordion-body {
  padding-left: 0px;
  padding-right: 0px;
}
.accordion-item {
  border: none !important;
  // border-radius: 0 !important;
}
.accordion-button {
  font-size: $btn-font-size-sm;
  font-weight: $font-weight-600;
  line-height: $line-height-16;
  background-color: $white;
  border: none;
  padding-left: 0px;
  padding-right: 0px;

  // border-radius: 0 !important;
  color: $g-900;
  &::after {
    background: transparent
      url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.11098 4.95L12.061 -6.18079e-08L13.475 1.414L7.11098 7.778L0.746975 1.414L2.16097 -4.94551e-07L7.11098 4.95Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A")
      center/1em auto no-repeat;
  }

  &:not(.collapsed) {
    background-color: $g-200;
    box-shadow: none;
    &::after {
      background: transparent
        url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.11098 4.95L12.061 -6.18079e-08L13.475 1.414L7.11098 7.778L0.746975 1.414L2.16097 -4.94551e-07L7.11098 4.95Z' fill='%231E1E1E'/%3E%3C/svg%3E%0A")
        center/1em auto no-repeat;
    }
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $g-400;
}
