.question {
  padding: 8px 1rem 0px 1rem;

  .star-rating {
    cursor: pointer;
  }

  .star-rating-counter {
    font-size: $font-size-lg;
  }

  .star-rating.active-rate {
    .star-rating-counter {
      color: $grape !important;
    }
  }

  .question-header {
    .question-number {
      @include label-s;
      color: $grape;
      width: 40px;
    }
  }

  .question-description {
    @include title;
    padding-left: 40px;
  }

  .question-description {
    padding-bottom: 8px;

    width: 100%;
    @include label-m;
  }

  .question-body {
    font-size: 16px;
    overflow-x: auto;
    padding: 16px 8px 20px 34px;
  }

  .question-body .question-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .question-footer {
    img {
      cursor: pointer;
      transition: transform $transition-duration;
    }

    .footer-icon {
      font-size: $font-size-base;
      color: $g-700;
      font-weight: $font-weight-300;
    }

    .footer-icon.active-icon {
      color: $grape;
    }

    .question-type-selector {
      max-width: max-content;
      min-width: 200px !important;
      display: block;
      text-align: center;
      padding: 2spx 10px;
      cursor: pointer;
      border: #25003e solid 1px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      border-radius: 3px;
    }
  }

  .dropzone {
    min-height: 160px;
    min-width: 260px;
  }
}
