.content-editor {
  line-height: 28px;
  position: relative;

  .lexical-rich-text-plugin-placeholder {
    position: absolute;
    color: $g-700;
    left: 0px;
    top: 0px;
    width: 350px;
    font-style: italic;
    font-weight: inherit;
  }

  font-family: 'IBM Plex Sans' !important;

  .textBold {
    font-weight: bold;
  }

  .textItalic {
    font-style: italic;
  }

  .content-editable {
    word-break: break-word;

    b,
    i {
      font-weight: normal;
      font-style: normal;
    }
  }

  .content-area,
  .content-editable {
    &:active,
    &:focus {
      outline: none;
      background-color: #d9d9d933;
    }
  }

  .content-area p {
    color: $g-900 !important;
    padding: 0;
    margin: 0;
  }
}

.sidebar-row-title .content-editable {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.survey-privacy .content-editable {
  width: 100% !important;
}

.editable-content-editor .content-editable {
  border: 2px solid $g-700 !important;
  border-radius: 4px !important;
  font-size: 0.9975rem !important;
  line-height: 16px !important;
  padding: 8px 12px !important;
  width: 100% !important;
}

.editable-content-editor.textarea .content-editable {
  min-height: 52px;
}

.content-editor-toolbar {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  min-width: 100px;

  .content-editor-toolbar-form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: $white;
    border-radius: 1px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    outline: 1px solid rgba(30, 30, 30, 1);

    .meatball-icon {
      border-left: $g-900 1px solid;
    }

    .toolbar-button {
      outline: none;
      border: none;
      border-radius: 0px;
      width: 40px;
      height: 48px;

      &:hover,
      &:focus,
      &:active {
        background-color: $white;
        outline: 2px solid $grape;
        box-shadow: none;
      }
    }
  }

  .separator {
    margin: -10px 0;
    padding: -10px 0;
  }
}

.content-editor-meatball-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  background: $white;
  outline: 1px solid rgba(30, 30, 30, 1);
  border-radius: 1px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 14px;
  margin-left: 4px;
  margin-top: -1px;
  z-index: 9999;
  min-width: 200px;

  hr {
    width: 250px;
    background-color: #ccc;
    margin: 0;
  }

  p.font-size,
  p.actions {
    font-weight: $font-weight-500;
    font-size: $font-size-xs;
    line-height: $line-height-16;
    color: $g-700;
    padding-left: 8px;
  }

  .small {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    line-height: $line-height-20;
    color: $g-900;
  }

  .medium {
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    line-height: $line-height-24;
    color: $g-900;
  }

  .large {
    font-weight: $font-weight-normal;
    font-size: $font-size-xl;
    line-height: $line-height-32;
    color: $g-900;
  }

  .toolbar-button {
    outline: none;
    border: none;
    border-radius: 0px;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      outline: 2px solid $grape;
      box-shadow: none;
    }
  }

  p {
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    font-weight: 500;
  }
}

.content-editor-placeholder-menu {
  display: block;
  gap: 2px;
  background: $white;
  outline: 1px solid rgba(30, 30, 30, 1);
  border-radius: 1px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-left: 4px;
  margin-top: -1px;
  z-index: 9999;
  min-width: 280px;

  hr {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    background-color: #ccc;
    height: 1px;
  }

  .small {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    line-height: $line-height-20;
    color: $g-900;
  }
}
