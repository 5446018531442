.survey-header-container {
  background-color: #a3c8ff44;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 100%;
  padding: 63px 67px;

  .show-x-questions {
    font-size: 16px;
    font-weight: normal;
  }

  .select {
    background-color: transparent;
    border: none;
    outline: none;
    width: fit-content;

    .react-select__menu {
      background-color: red !important;
    }

    *,
    svg {
      color: #5076ff !important;
      fill: #5076ff;
      padding: 0;
      //styleName: LSD/Typography/Sans/Semi14;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.017em;
      text-align: left;
    }
  }

  .start-survey-section {
    .or-press-enter-text {
      font-size: 18px;
      color: $g-700 !important;
      font-weight: 400;
      font-family: IBM Plex Sans;
    }

    .start-button {
      width: 101px;
      height: 44px;
      transition: box-shadow 0.2s;
      border-radius: 4px;
      background: $active-green;
      border: none;
      outline: none;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.015em;
      text-align: left;
      color: $white;

      &:hover {
        background: $active-green-hover;
      }
    }
  }

  h6,
  h4 {
    font-weight: 400;
    color: #757575;
  }

  h4 {
    color: $black75 !important;
  }

  .language-change-header {
    color: $g-700;
    margin-bottom: 18px;
  }

  .welcome-title {
    margin-bottom: 18px;
    font-size: 40px;
    letter-spacing: -0.017em;
    text-align: left;

    * {
      font-family: 'IBM';
      line-height: 125%;
    }
  }

  .welcome-description {
    font-size: 18px;
    letter-spacing: -0.017em;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .collapse-control {
    color: $g-700;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .border-bottom {
    border-bottom: 1px rgb(220, 220, 220) solid;
  }
}
