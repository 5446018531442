.right-side-bar {
  position: fixed;
  right: 0;
  width: $right-side-bar-width;
  overflow-y: auto;
  max-height: calc(100vh - $top-bar-height); // 56px is the topbar height.
  background: $g-100;
  opacity: 1 !important;
  border-left: 1px #eeeeee solid;
  scrollbar-gutter: stable;

  .focused-question-code {
    cursor: pointer;
  }

  a {
    color: black;
  }

  .question-setting,
  .advanced-toggle,
  .accordion-button {
    padding-left: 16px;
    padding-right: 12px;
  }
}

.right-side-bar.active-side-bar {
  background: $white !important;
}
