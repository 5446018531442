.survey-footer-container {
  border-radius: 2px;
  width: 100%;
  background-color: #a3c8ff44;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 63px 67px;

  h6,
  h4 {
    font-weight: 400;
    color: #757575;
  }

  h4 {
    color: $black75 !important;
  }

  .collapse-control {
    color: $g-700;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .border-bottom {
    border-bottom: 1px rgb(220, 220, 220) solid;
  }

  .finish-button {
    width: 101px;
    height: 44px;
    transition: box-shadow 0.2s;
    border-radius: 4px;
    background: $active-green;
    border: none;
    outline: none;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.015em;
    text-align: left;
    color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: $active-green-hover;
    }
  }
}
