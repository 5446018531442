@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

input[type='date'].selected::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../assets/icons/calendar-selected.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

html {
  font-family: 'IBM Plex Sans' !important;
  font-weight: 400 !important;
  position: relative;
  min-height: 100%;
  font-size: $font-size-root;
  background-color: $g-100;
}

#content {
  background-color: $g-100 !important;
  height: 100%;
  margin-top: 60px;
}

a {
  text-decoration: none;
}

#container {
  height: 100%;
  background-color: #f6f7f7;
}

.remove-option-button-parent .remove-option-button {
  opacity: 0;
}

.remove-option-button-parent:hover .remove-option-button {
  cursor: pointer;
  opacity: 1;
}

.qe-input-group {
  .form-control {
    height: 40px;
    border-radius: 4px;
    border: 2px solid $g-700;
  }

  .qe-input-icon-right,
  .qe-input-icon-left {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: 10;
  }

  .qe-input-icon-right {
    right: 0px;
  }

  .qe-input-icon-left {
    left: 0px;
  }
}

.fw-500 {
  font-weight: 500;
}

[data-placeholder]:empty {
  text-align: left !important;
  font-style: italic;

  &:before {
    color: $g-700;
    cursor: text;
    content: attr(data-placeholder);
    -webkit-content: attr(data-placeholder);
    -moz-content: attr(data-placeholder);
    -ms-content: attr(data-placeholder);
  }
}

.focus-element {
  border-radius: 2px;
  background: $white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  outline: 3px solid $grape;
  z-index: 10;
}

[data-error] {
  outline: 1px solid $error;
  transition: all $transition-duration;
  border-radius: 2px;
}

.hover-element {
  &:hover {
    outline: 3px solid $hover-color;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
}

.error-focus {
  border-radius: 2px;
  background: $white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  outline: 2px solid $error;
  z-index: 10;
}

.swal2-container {
  .swal2-title {
    font-size: 20px;
  }
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.transition-all {
  transition: all $transition-duration;
}

.cursor-not-allowed {
  cursor: not-allowed !important;

  * {
    cursor: not-allowed !important;
  }
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
  cursor: not-allowed !important;
}

.participant-hidden {
  opacity: 0.25;
  cursor: default;
}

// Disable border color between thead, tbody.
.table > :not(:first-child) {
  border-top: 0;
}

.ls-text-underline-hover {
  text-decoration: none;
}

.ls-text-underline-hover:hover {
  text-decoration: underline;
}
.divider {
  border-color: $g-400;
}
.question-code {
  width: 79px;
}

.right-sidebar-attribute {
  margin-top: 1rem;
}

.main-body {
  width: 100%;
  // width: calc(100% - $left-side-bar-width - $right-side-bar-width);
  // position: absolute;
  // left: $left-side-bar-width;
  margin-left: $left-side-bar-width;
  background: $g-100;
  margin-right: $right-side-bar-width;
}

.survey-part {
  max-width: 838px;
  margin: auto;
  z-index: 3;
}

.inner-wrap {
  position: absolute;
  inset: 0;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.adding-question-group-wrapper {
  background: rgba(243, 243, 243, 0.3);
  position: fixed;
  inset: 0;
  z-index: 100;
}

.auto-saved {
  font-size: $font-size-sm;
  min-width: max-content;
}

.swal2-popup {
  width: fit-content;
}

h1 {
  font-size: 32pt;
  font-weight: 300;
  padding: 0 !important;
  margin: 0;
}

h2 {
  font-size: $font-size-xl !important;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

h3 {
  font-size: 16pt;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

p {
  line-height: 28px;
  padding: 0;
  margin: 0;
}

.preview-button {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 3px;
  background: $g-200;
}

.attribute-label,
.ui-label {
  @include label-s-sidebar;
}

.select-option,
.choice {
  @include choice;
  outline: none;

  &:has(:checked) {
    .single-choice-form-label {
      color: $active-green;
    }
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.add-choice-button {
  color: $grape;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.21px;
}

.added-choice-number {
  color: $grape;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.21px;
}

.toggle-button {
  @include med14-c;
}

.button {
  @include med14-c;
}

.form-control:disabled {
  cursor: not-allowed;
}

.swal2-close {
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  transition: color 0.1s ease-out;
  border: none;
  background: transparent;
  font-size: 40px;
  cursor: pointer;
  color: #ccc;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    color: #ccc;
  }
}

.loader {
  width: 100%;
  height: 100%;

  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 4px solid $grape;
    border-left: 4px solid transparent;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.mouse-pointer {
  cursor: pointer;
}

.active-green-color {
  color: $active-green !important;
}

.error-toast {
  background-color: $error;
  border: 1px $g-900 solid;
  border-radius: 8px;
  min-width: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: large;
  align-items: center;
}

.success-toast {
  background-color: $success;
  border: 1px $g-900 solid;
  border-radius: 8px;
  min-width: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: large;
  align-items: center;
}

.animate-enter {
  opacity: 1;
  transition: all 0.5s ease;
}

.animate-leave {
  opacity: 0;
  transition: all 0.5s ease;
}

.question-code .content-editable {
  width: 120px !important;
}

.footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  width: 190px;
  font-size: 70%;
}

.text-sm {
  font-size: 12px;
}
