.add-sub-question-button {
  width: fit-content;
}

.multiple-choice-checkbox-subquestion {
  min-height: 38px;
}

.multi-choice-checkbox-label {
  width: max-content;
}

.multiple-choice-question {
  .button {
    font-size: 1.14rem;
  }

  .multiple-choice-subquestion-content-editor {
    margin-left: 12px;
  }

  .answer-item {
    margin-bottom: 12px;
  }
}
