.top-bar {
  position: fixed;
  background-color: white;
  align-items: center;
  z-index: 999;
  top: 0;
  width: 100vw;
  &-left {
    width: $left-side-bar-width;
    // z-index is set due to middle part
    z-index: 2;
  }

  &-middle {
  }

  &-right {
    min-width: $right-side-bar-width;
    z-index: 2;
  }

  height: $top-bar-height;

  .top-bar-select {
    border: 0;
    background-color: transparent;
    font-weight: $font-weight-500;
    font-size: $font-size-sm;
    position: relative;

    .survey-title-content-editor {
      z-index: 100;
      color: $black;
      background-color: $white;

      &:focus,
      &:active {
        background-color: $g-200 !important;
      }
    }

    .form-select-top-bar {
      position: absolute;
      margin-left: 25px;
      border: none;
      outline: none;
      color: $white;
      z-index: 1;

      &:active,
      &:focus {
        color: black;
      }
    }
  }

  box-shadow: 0 1px 2px -2px gray;

  .top-bar-brand {
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    color: #1e1e1e;
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 24px;
    width: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-right: 0px;

    .logo {
      padding-left: 3px;
      vertical-align: bottom;
      display: inline-block;
    }
  }

  .button {
    height: 36px;
  }

  .add-question-button {
    padding: 0;
    height: 36px;
    width: 36px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
