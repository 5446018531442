// This is classes come from Tailwind CSS for easy use
.fill-current {
  fill: currentColor;
}
.cursor-pointer {
  cursor: pointer;
}
.border-none {
  border-style: none !important;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.pointer-events-none {
  pointer-events: none;
}
